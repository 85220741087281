import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { TKeyFilterParams } from '../AccountingReports/Ggr';
import ClasterFilterItem from './ClasterFilterItem ';
import { CountryFilters, countryFilters, CurrencyFilters, currencyFilters } from './filters';
import MainFilterItem from './MainFilterItem';
import style from './style.module.scss';

interface FilterItem {
  name: string;
  slug: string;
  selected?: boolean;
}

interface FilterGroupProps extends React.PropsWithChildren {
  slots: FilterItem[];
  clients: FilterItem[];
  operators: FilterItem[];
  currency: FilterItem[];
  clientCurrency: FilterItem[];
  clientCountry: FilterItem[];
  country: FilterItem[];
  selectedSlots: FilterItem[];
  selectedClients: FilterItem[];
  selectedOperators: FilterItem[];
  selectedCurrency: FilterItem[];
  selectedClientCurrency: FilterItem[];
  selectedClientCountry: FilterItem[];
  selectedCountry: FilterItem[];
  onChangeBalanceSummary: (options: FilterItem[]) => void;
  onCheckboxChange: (filter: TKeyFilterParams, options: FilterItem[]) => void;
  showCountryFilterItem: boolean;
  onApplyFilters?: () => void;
  testersSelector: React.ReactElement;
  applyButtonClicked?: boolean;
}

const MainFilter: React.FC<FilterGroupProps> = ({
  slots,
  clients,
  operators,
  currency,
  clientCurrency,
  clientCountry,
  country,
  selectedSlots,
  selectedClients,
  selectedOperators,
  selectedCurrency,
  selectedCountry,
  onCheckboxChange,
  showCountryFilterItem,
  applyButtonClicked,
  testersSelector,
  children,
}) => {
  const [isAnySlotChecked, setIsAnySlotChecked] = useState(false);
  const [isAnyOperatorChecked, setIsAnyOperatorChecked] = useState(false);
  const [isAnyClientChecked, setIsAnyClientChecked] = useState(false);
  const [isAnyCurrencyChecked, setIsAnyCurrencyChecked] = useState(false);
  const [isAnyCountryChecked, setIsAnyCountryChecked] = useState(false);

  const getCountries = (selectedSlug: CountryFilters) =>
    country
      .filter((country) => countryFilters[`${selectedSlug}`].includes(country.slug))
      .map((country) => ({ ...country, selected: true }));
  const getCurrencies = (selectedSlug: CurrencyFilters) =>
    currency
      .filter((currency) => currencyFilters[`${selectedSlug}`].includes(currency.slug))
      .map((currency) => ({ ...currency, selected: true }));
  const handleCurenciesChange = (options: FilterItem) =>
    onCheckboxChange('currencies', getCurrencies(options.slug as CurrencyFilters));

  const handleCountiresChange = (options: FilterItem) =>
    onCheckboxChange('countries', getCountries(options.slug as CountryFilters));

  useEffect(() => {
    setIsAnySlotChecked(selectedSlots.length > 0);
  }, [selectedSlots]);

  useEffect(() => {
    setIsAnyOperatorChecked(selectedOperators.length > 0);
  }, [selectedOperators]);

  useEffect(() => {
    setIsAnyClientChecked(selectedClients.length > 0);
  }, [selectedClients]);

  useEffect(() => {
    setIsAnyCurrencyChecked(selectedCurrency.length > 0);
  }, [selectedCurrency]);

  useEffect(() => {
    setIsAnyCountryChecked(selectedCountry.length > 0);
  }, [selectedCountry]);

  return (
    <div className={style['main-filter']}>
      <div className={style['main-filter__col']}>
        <div className={`${style.title} ${style.operators}`}>{t('operators')}</div>
        <MainFilterItem
          isError={!isAnyOperatorChecked && applyButtonClicked}
          title={t('all')}
          options={operators}
          showInvertItem={false}
          selectedOptions={selectedOperators}
          onCheckboxChange={(options) => {
            onCheckboxChange('operators', options);
            onCheckboxChange('clients', []);
          }}
        />
      </div>
      <div className={style['main-filter__col']}>
        <div className={`${style.title} ${style.clients}`}>{t('clients')}</div>
        <MainFilterItem
          isError={!isAnyClientChecked && applyButtonClicked}
          title={t('all')}
          options={clients}
          showInvertItem={false}
          selectedOptions={selectedClients}
          onCheckboxChange={(options) => onCheckboxChange('clients', options)}
        />
      </div>
      <div className={style['main-filter__col']}>
        <div className={style.title}>{t('games')}</div>
        <MainFilterItem
          isError={!isAnySlotChecked && applyButtonClicked}
          title={t('all')}
          options={slots}
          showInvertItem={false}
          selectedOptions={selectedSlots}
          onCheckboxChange={(options) => onCheckboxChange('slots', options)}
        />
        {testersSelector}
      </div>
      <div className={style['main-filter__col']}>
        <div className={`${style.title} ${style.metrics}`}>{t('currencies')}</div>
        <MainFilterItem
          isError={!isAnyCurrencyChecked && applyButtonClicked}
          title={t('all')}
          options={currency}
          showInvertItem={true}
          selectedOptions={selectedCurrency}
          onCheckboxChange={(options) => onCheckboxChange('currencies', options)}
        />
        {showCountryFilterItem && <ClasterFilterItem options={clientCurrency} onChange={handleCurenciesChange} />}
      </div>
      {showCountryFilterItem && (
        <div className={style['main-filter__col']}>
          <div className={`${style.title} ${style.countries}`}>{t('countries')}</div>
          <MainFilterItem
            isError={showCountryFilterItem && applyButtonClicked && !isAnyCountryChecked}
            title={t('all')}
            options={country}
            showInvertItem={true}
            selectedOptions={selectedCountry}
            onCheckboxChange={(options) => onCheckboxChange('countries', options)}
          />
          <div>
            <ClasterFilterItem options={clientCountry} onChange={handleCountiresChange} />
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default MainFilter;
