import React from 'react';
import { Typography } from 'antd';
import pk from '../../../package.json';

const { Text } = Typography;

function Version() {
  return <Text code>version: {pk.version}</Text>;
}

export default Version;
