import { initReactI18next } from 'react-i18next';
import enUS from 'antd/es/locale/en_US';
import jaJP from 'antd/es/locale/ja_JP';
import ukUA from 'antd/es/locale/uk_UA';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';

export const languages = [
  {
    name: 'English',
    key: 'en',
    value: enUS,
  },
  {
    name: 'Українська',
    key: 'uk',
    value: ukUA,
  },
  {
    name: '日本',
    key: 'ja',
    value: jaJP,
  },
];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(
    resourcesToBackend((language: string) => {
      return import(`./resources.${language}.json`);
    }),
  )
  .init({
    fallbackLng: 'en',
    preload: ['en'],
    load: 'languageOnly',
  });

export default i18n;
