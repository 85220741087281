import { gql } from '@apollo/client';
import {
  BALANCE_SUMMARY,
  BONUS,
  BONUS_AGGREGATION_CHECK,
  OPERATOR,
  OPERATOR_ON_SLOT,
  RTP,
  SLOT,
  STATISTIC,
  STATISTIC_TOTAL,
  USER,
  USER_BONUS,
} from './fragment';

export const getMetricsGql = gql`
  ${STATISTIC_TOTAL}
  query GetBillingTotalStatistic($filter: MainArgs!) {
    billingTotalStatistic(filter: $filter) {
      ...statisticTotal
    }
  }
`;

export const getDetailsMetricsGql = gql`
  ${STATISTIC}
  query GetBillingStatistic($filter: MainArgs) {
    billingStatistic(filter: $filter) {
      statistic {
        ...statistic
      }
      count
    }
  }
`;

export const meGql = gql`
  ${USER}
  query me {
    me {
      ...user
    }
  }
`;

export const getCountriesGql = gql`
  query countries {
    countries
  }
`;

export const getCurrenciesGql = gql`
  query currencies($all: Boolean) {
    currencies(all: $all)
  }
`;

export const getSlotsGql = gql`
  ${SLOT}
  query slots($take: Int, $skip: Int, $operators: [UUID!]) {
    slots(take: $take, skip: $skip, operators: $operators) {
      ...slot
    }
  }
`;

export const getOperatorsGql = gql`
  ${OPERATOR}
  query operators {
    operators {
      ...operator
    }
  }
`;

export const getOperatorsSingleRoundGql = gql`
  ${OPERATOR}
  query operators {
    operators(singleRoundOnly: true) {
      ...operator
    }
  }
`;

export const getBonusAggregationCheckGql = gql`
  ${BONUS_AGGREGATION_CHECK}
  query BonusAggregationCheck($filter: BonusAggregationArgs!) {
    bonusAggregationCheck(filter: $filter) {
      ...bonusAggregationCheck
    }
  }
`;

export const getOperatorOnSlotGql = gql`
  ${OPERATOR_ON_SLOT}
  ${SLOT}
  query operator($operatorId: UUID!) {
    operator(operatorId: $operatorId) {
      ...operatorOnSlots
    }
  }
`;

export const getProfilesGql = gql`
  ${USER}
  query profiles {
    profiles {
      ...profile
    }
  }
`;

export const getRtpGql = gql`
  ${RTP}
  query rtp($filter: RtpArgs) {
    rtp(filter: $filter) {
      ...rtp
    }
  }
`;

export const getBonusesGql = gql`
  ${BONUS}
  query bonuses {
    bonuses {
      ...bonus
    }
  }
`;

export const getUsesBonusesGql = gql`
  ${USER_BONUS}
  query userBonuses($filter: BonusArgs!, $take: Int, $skip: Int) {
    userBonuses(filter: $filter, take: $take, skip: $skip) {
      ...userBonus
    }
  }
`;

export const getBalanceSummaryGql = gql`
  ${BALANCE_SUMMARY}
  query GetBillingTotalStatistic($filter: MainArgs!) {
    billingTotalStatistic(filter: $filter) {
      ...statisticTotal
    }
  }
`;
