import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { FilterParams } from './Ggr';

dayjs.extend(utc);
dayjs.extend(timezone);

export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

const useTimezoneRangepicker = () => {
  const [timezone, setTimezone] = useState(dayjs.tz.guess());

  const [dateFrom, setDateFrom] = useState(dayjs().toISOString());
  const [dateTo, setDateTo] = useState(dayjs().toISOString());

  const [filterParams, setFilterParams] = useState<FilterParams>({
    from: dateFrom,
    to: dateTo,
    clients: [],
    operators: [],
    currencies: [],
    slots: [],
    countries: [],
    clientCurrencies: [],
    clientCountries: [],
  });

  const handleTimezoneChange = (timezone: string) => {
    setTimezone(timezone || dayjs.tz.guess());
  };

  useEffect(() => {
    setFilterParams({
      ...filterParams,
      from: dayjs(dateFrom).startOf('day').tz(timezone, true).format(DATE_FORMAT),
      to: dayjs(dateTo).endOf('day').tz(timezone, true).format(DATE_FORMAT),
    });
  }, [timezone, dateFrom, dateTo]);

  const datepickerChangeHandler = (v: unknown, date: [string, string]) => {
    if (date[0] !== dateFrom || date[1] !== dateTo) {
      setDateFrom(date[0]);
      setDateTo(date[1]);
    }
  };

  return {
    dateFrom,
    dateTo,
    handleTimezoneChange,
    datepickerChangeHandler,
    filterParams,
    setFilterParams,
    timezone,
  };
};

export default useTimezoneRangepicker;
