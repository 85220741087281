export type CurrencyFilters = 'Asia-PronetGaming' | 'Asia-iGP';
export type CountryFilters = 'Asia-Anakatech' | 'Asia-BetConstruct' | 'Asia-Hub88';

export const currencyFilters = {
  'Asia-PronetGaming': [
    'BND',
    'KHR',
    'IDR',
    'INR',
    'LAK',
    'MYR',
    'MMK',
    'MOP',
    'PHP',
    'SGD',
    'THB',
    'USD',
    'VND',
    'CNY',
    'HKD',
    'TWD',
  ],
  'Asia-iGP': ['KHR', 'IDR', 'LAK', 'MYR', 'MOP', 'PHP', 'SGD', 'THB', 'USD', 'VND', 'CNY', 'HKD'],
  'Asia-P7': ['KHR', 'IDR', 'LAK', 'MYR', 'MOP', 'PHP', 'SGD', 'THB', 'USD', 'VND', 'CNY', 'HKD'],
};

export const countryFilters = {
  'Asia-Anakatech': ['BN', 'IN', 'LA', 'SG', 'TH', 'TL', 'VN', 'JP'],
  'Asia-BetConstruct': ['KH', 'ID', 'IN', 'MY', 'MM', 'MO', 'PH', 'SG', 'TH', 'VN', 'CN', 'HK'],
  'Asia-Hub88': ['KH', 'ID', 'IN', 'MY', 'SG', 'TH', 'VN', 'CN', 'HK', 'TW', 'JP', 'BD', 'KR', 'NP', 'PK', 'LK', 'TR'],
};
