import React, { useState } from 'react';
import { Button, Checkbox, Input, Typography } from 'antd';
import { t } from 'i18next';
import style from './style.module.scss';

interface FilterItem {
  name: string;
  slug: string;
}

interface MainFilterItemProps {
  title?: string;
  isError?: boolean;
  options: FilterItem[];
  showInvertItem: boolean;
  selectedOptions: FilterItem[];
  onCheckboxChange: (options: FilterItem[]) => void;
  onApplyFilters?: () => void;
}

const MainFilterItem: React.FC<MainFilterItemProps> = ({
  title,
  options,
  showInvertItem,
  isError,
  selectedOptions,
  onCheckboxChange,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleCheckboxChange = (slug: string) => {
    const updatedOptions = selectedOptions.some((option) => option.slug === slug)
      ? selectedOptions.filter((option) => option.slug !== slug)
      : [...selectedOptions, options.find((option) => option.slug === slug)!];
    onCheckboxChange(updatedOptions);
  };

  const handleSelectAll = () => {
    const allSelected = selectedOptions.length === options.length;
    const updatedOptions = allSelected ? [] : options;
    onCheckboxChange(updatedOptions);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredOptions = options.filter((option) => option.name.toLowerCase().includes(searchTerm.toLowerCase()));

  const handleClearSearch = () => {
    setSearchTerm('');
  };
  const invertSelected = () => {
    const updatedOptions = options
      .map((option) => {
        if (selectedOptions.some((selectedOption) => selectedOption.slug === option.slug)) {
          return null;
        } else {
          return option;
        }
      })
      .filter((option) => option !== null);
    onCheckboxChange(updatedOptions as FilterItem[]);
  };

  return (
    <div>
      <div className={style.errorMsg}>
        {isError && <Typography.Text type="danger">{t('checkAllCheckboxes')}</Typography.Text>}
      </div>
      <div className={style.selectionRow}>
        <Checkbox
          className={style.checkbox}
          checked={options.length > 0 && selectedOptions.length === options.length}
          onChange={handleSelectAll}
        >
          {title}
        </Checkbox>
        {showInvertItem && (
          <Button className={style.invertButton} onClick={invertSelected}>
            Invert Selection
          </Button>
        )}
      </div>

      <Input
        className={style.input}
        prefix={<span className="material-symbols-outlined">search</span>}
        suffix={
          searchTerm && (
            <span className="material-symbols-outlined" onClick={handleClearSearch}>
              close
            </span>
          )
        }
        value={searchTerm}
        onChange={handleSearchChange}
      />

      <div className={style.filterItemsWrap}>
        {filteredOptions.map((option) => (
          <Checkbox
            key={option.slug}
            className={style.checkbox}
            checked={selectedOptions.some((selectedOption) => selectedOption.slug === option.slug)}
            onChange={() => handleCheckboxChange(option.slug)}
          >
            {option.name}
          </Checkbox>
        ))}
      </div>
    </div>
  );
};

export default MainFilterItem;
