import React from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'supertokens-auth-react/recipe/thirdparty';
import { useQuery } from '@apollo/client';
import { Button } from 'antd';
import { t } from 'i18next';
import { meGql } from '../../../graphql';
import styles from './me.module.scss';

function Me() {
  const navigate = useNavigate();

  async function onLogout() {
    await signOut();
    navigate('/signin');
  }
  const { data } = useQuery<{ me: { email: string } }>(meGql);

  return (
    <div className={styles.me}>
      <div className={styles.email}>{data?.me.email}</div>
      <Button type="primary" key="logout" onClick={onLogout}>
        {t('logout')}
      </Button>
    </div>
  );
}

export default Me;
