import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { FilterParams } from './BonusAggregation';

dayjs.extend(utc);
dayjs.extend(timezone);

export const DATE_FORMAT = 'YYYY-MM-DDTHH:00:00Z';

const useTimezoneRangepicker = () => {
  const [timezone, setTimezone] = useState(dayjs.tz.guess());

  const [startDateTime, setStartDate] = useState(dayjs().toISOString());
  const [endDateTime, setEndDate] = useState(dayjs().toISOString());

  const [filterParams, setFilterParams] = useState<FilterParams>({
    startDateTime: startDateTime,
    endDateTime: endDateTime,
    clients: [],
    operators: [],
  });

  const handleTimezoneChange = (timezone: string) => {
    setTimezone(timezone || dayjs.tz.guess());
  };

  useEffect(() => {
    setFilterParams({
      ...filterParams,
      startDateTime: dayjs(startDateTime).startOf('day').tz(timezone, true).format(DATE_FORMAT),
      endDateTime: dayjs(endDateTime).endOf('day').tz(timezone, true).format(DATE_FORMAT),
    });
  }, [timezone, startDateTime, endDateTime]);

  const datepickerChangeHandler = (v: unknown, date: [string, string]) => {
    if (date[0] !== startDateTime || date[1] !== endDateTime) {
      setStartDate(date[0]);
      setEndDate(date[1]);
    }
  };

  return {
    startDateTime,
    endDateTime,
    handleTimezoneChange,
    datepickerChangeHandler,
    filterParams,
    setFilterParams,
    timezone,
  };
};

export default useTimezoneRangepicker;
