import React from 'react';
import { Avatar, Button, Dropdown } from 'antd';
import i18n, { languages } from '../../i18next';
import styles from './styles.module.scss';

export function LanguageSelector() {
  const [, setLocal] = React.useState(languages[0]);

  const changeLanguage = (lang: string) => {
    const lng = languages.find((item) => item.key === lang);
    if (lng) {
      i18n.changeLanguage(lng.key);
      setLocal(lng);
    }
  };
  const items = React.useMemo(
    () =>
      languages.map((lng) => ({
        label: (
          <Button
            type="text"
            onClick={() => changeLanguage(lng.key)}
            size="small"
            icon={
              <span style={{ marginRight: 8 }}>
                <Avatar size={16} src={`/flags/${lng.key}.svg`} />
              </span>
            }
          >
            {lng.name}
          </Button>
        ),
        key: lng.key,
      })),
    [],
  );

  return (
    <Dropdown className={styles.lang_selector} trigger={['click']} menu={{ items }}>
      <div className={styles.lang_selector__wrap}>
        <Avatar className={styles.avatar} size={16} src={`/flags/${i18n.language}.svg`} />
        {
          languages.find((item) => {
            return item.key === i18n.language;
          })?.name
        }
        <span className="material-symbols-outlined">keyboard_arrow_down</span>
      </div>
    </Dropdown>
  );
}
