import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { Table } from 'antd';
import { getOperatorsGql } from '../../graphql';
import { TOperator } from '../../types';
import ClientOnSlot from './ClientOnSlot';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '50%',
  },
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  },
];

function ClientList() {
  const { data, loading } = useQuery<{ operators: TOperator[] }>(getOperatorsGql);

  const { t } = useTranslation();

  return (
    <div className="main_container">
      <h1 className="heder_title">{t('clientTitle')}</h1>
      <Table
        loading={loading}
        columns={columns}
        rowKey="id"
        dataSource={data?.operators}
        pagination={{ position: ['bottomCenter'] }}
        expandable={{
          expandedRowRender: (record) => <ClientOnSlot operatorId={record.id} />,
          rowExpandable: (record) => record.id !== 'Not Expandable',
        }}
      />
    </div>
  );
}

export default ClientList;
