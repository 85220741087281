import React from 'react';
import { Button } from 'antd';
import style from './style.module.scss';

interface FilterItem {
  name: string;
  slug: string;
}

interface ClasterFilterItemProps {
  title?: string;
  options: FilterItem[];
  onChange: (options: FilterItem) => void;
}

const ClasterFilterItem: React.FC<ClasterFilterItemProps> = ({ options, onChange }) => {
  const handleChange = (slug: string) => {
    const newlySelectedOption = options.find((option) => option.slug === slug);
    if (onChange && newlySelectedOption) {
      onChange(newlySelectedOption);
    }
  };

  return (
    <div>
      <div className={style.radioFilterItemsWrap}>
        {options.map((option) => (
          <Button key={option.slug} className={style.radio} onClick={() => handleChange(option.slug)}>
            {option.name}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default ClasterFilterItem;
