import React from 'react';
import { useQuery } from '@apollo/client';
import { Spin, Table, TableColumnsType, Tag } from 'antd';
import { getOperatorOnSlotGql } from '../../graphql';
import { TClientOnSlot } from '../../types';

// const randomColor = (v = '#'): string => {
//   const r = v + (Math.random() * 10).toFixed();
//   if (r.length <= 3) {
//     return randomColor(r);
//   }
//   return r;
// };

const columns: TableColumnsType<TClientOnSlot> = [
  { title: 'Licenses', key: 'licenses', render: (v: TClientOnSlot) => v.licenses.map((i) => <p key={i}>{i}</p>) },
  { title: 'Slots', key: 'slots', render: (v: TClientOnSlot) => v.slots.map((i) => <Tag key={i.id}>{i.name}</Tag>) },
];

type PropsClientOnSlot = {
  operatorId: string;
};

function ClientOnSlot({ operatorId }: PropsClientOnSlot) {
  const { data } = useQuery<{ operator: TClientOnSlot }>(getOperatorOnSlotGql, {
    variables: {
      operatorId,
    },
  });

  if (!data?.operator) {
    return <Spin size="large" />;
  }

  return <Table columns={columns} dataSource={[data.operator]} size="small" pagination={false} />;
}

export default ClientOnSlot;
