import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Button, DatePicker, Select, Table } from 'antd';
import dayjs from 'dayjs';
import { getBonusAggregationCheckGql, getOperatorsSingleRoundGql } from '../../graphql';
import { TBonusAggregation, TOperator } from '../../types';
import MainBonusFilter from '../MainFilter/MainBonusFilter';
import mainFilterStyle from '../MainFilter/style.module.scss';
import styles from './styles.module.scss';
import useTable from './useTable';
import useTimezoneRangepicker from './useTimezoneRangepicker';

export interface FilterParams {
  startDateTime: string;
  endDateTime: string;
  clients: FilterItem[];
  operators: FilterItem[];
}

interface FilterParamsGql {
  startDateTime: string;
  endDateTime: string;
  operatorIds?: string[];
  clients?: string[];
}

export type TKeyFilterParams = keyof Omit<FilterParams, 'startDateTime' | 'endDateTime'>;

export interface FilterItem {
  name: string;
  slug: string;
}

const BonusAggregation = () => {
  const { t } = useTranslation();
  const sortRef = useRef({
    sorter: (_a: TBonusAggregation, _b: TBonusAggregation) => 0 as number,
    order: '',
  });
  const { columns, formatTableData } = useTable();

  const {
    startDateTime,
    endDateTime,
    handleTimezoneChange,
    filterParams,
    setFilterParams,
    datepickerChangeHandler,
    timezone,
  } = useTimezoneRangepicker();

  const [showCountryFilterItem] = useState(true);

  const handleTableChange = (_pagination: unknown, _filters: unknown, _sorter: unknown) => {
    setSelectedRowKeys([]);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [allData, setAllData] = useState<TBonusAggregation[]>([]);
  const [applyButtonClicked, setApplyButtonClicked] = useState(false);
  const [tableData, setTableData] = useState<TBonusAggregation[]>([]);
  const [tableDataToShow, setTableDataToShow] = useState<TBonusAggregation[]>([]);

  const updateTableData = () => {
    if (metricsData?.bonusAggregationCheck.length) {
      const { data } = formatTableData(metricsData?.bonusAggregationCheck, operatorsData?.operators!);
      setTableData(data);
    } else {
      setTableData([]);
      setAllData([]);
    }
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [getBilling, { data: metricsData, loading: metricsLoading }] = useLazyQuery<
    { bonusAggregationCheck: TBonusAggregation[] },
    { filter: FilterParamsGql }
  >(getBonusAggregationCheckGql);
  const { data: operatorsData } = useQuery<{ operators: TOperator[] }>(getOperatorsSingleRoundGql);
  useEffect(() => {
    if (metricsData && !metricsLoading) {
      updateTableData();
    }
  }, [metricsData, metricsLoading]);

  const operators = useMemo(
    () =>
      operatorsData?.operators
        .map((item) => ({ name: item.name, slug: item.id }))
        .sort((a, b) => a.name.localeCompare(b.name)) as FilterItem[],
    [operatorsData?.operators],
  );

  const clients = useMemo(
    () =>
      operatorsData?.operators
        .filter((operator) => filterParams.operators.find((operatorFilter) => operatorFilter.slug === operator.id))
        .reduce((acc, operator) => acc.concat(operator.clients), [] as { id: string; name: string }[])
        .map((item) => ({ name: item.name, slug: item.id }))
        .filter((element, index, array) => index === array.findIndex((findElem) => findElem.slug === element.slug))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [operatorsData?.operators, filterParams.operators],
  );

  const onSelectAllChange = (selected: boolean) => {
    if (selected) {
      const keys = allData.map((row: TBonusAggregation) => row.clientId);
      setSelectedRowKeys(keys);
    } else {
      setSelectedRowKeys([]);
    }
  };
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    preserveSelectedRowKeys: true,
    onSelectAll: onSelectAllChange,
    hideSelectAll: true,
  };

  const handleCheckboxChange = React.useCallback((filter: TKeyFilterParams, updatedFilters: FilterItem[]) => {
    setFilterParams((prev) => {
      return {
        ...prev,
        [filter]: updatedFilters,
      };
    });
  }, []);

  useEffect(() => {
    if (metricsData && metricsData.bonusAggregationCheck) {
      setAllData(metricsData.bonusAggregationCheck);
    }
  }, []);

  useEffect(() => {
    setTableDataToShow(tableData);
    return;
  }, [tableData]);

  const processFilterParams = (
    filterParams: FilterParams | { [s: string]: string | FilterItem[] } | ArrayLike<string | FilterItem[]>,
  ) => {
    const data: [k: string, string | FilterItem[]][] = Object.entries(filterParams);
    const filter = data.reduce((acc, [k, v]) => {
      if (k.includes('clientC') || k.includes('operators')) {
        return acc;
      }
      if (Array.isArray(v)) {
        return {
          ...acc,
          [k]: v.map((i) => i.slug),
        };
      }
      return {
        ...acc,
        [k]: v,
      };
    }, {} as FilterParamsGql);
    filter.operatorIds = filter.clients!;
    delete filter.clients;
    return filter;
  };

  const handleFilterParams = (filterParams: FilterParams) => {
    getBilling({
      variables: {
        filter: processFilterParams(filterParams),
      },
    });
  };

  return (
    <div className={styles.main_container}>
      <h1 className="heder_title">{t('menuBam')}</h1>
      <div className={styles['table-top-space']}>
        <MainBonusFilter
          operators={operators || []}
          clients={clients || []}
          selectedOperators={filterParams.operators}
          selectedClients={filterParams.clients}
          showCountryFilterItem={showCountryFilterItem}
          onCheckboxChange={(filter: string, updatedFilters: FilterItem[]) =>
            handleCheckboxChange(filter as TKeyFilterParams, updatedFilters)
          }
          applyButtonClicked={applyButtonClicked}
        >
          <div className={`${mainFilterStyle['main-filter__col']}  ${styles.timezonePickerContainer}`}>
            <div className={mainFilterStyle.title}>{t('fromTo')}</div>
            <Select showSearch value={timezone} onChange={handleTimezoneChange} className={styles.timezoneSelect}>
              {Intl.supportedValuesOf('timeZone').map((timezone) => (
                <Select.Option key={timezone} value={timezone}>
                  {`${dayjs().tz(timezone).format('Z')} ${timezone} `}
                </Select.Option>
              ))}
            </Select>
            <div className={`${styles.timeRangeWrapper}`}>
              <DatePicker.RangePicker
                format={'YYYY-MM-DD'}
                className={styles.calendar}
                defaultValue={[dayjs(startDateTime), dayjs(endDateTime)]}
                onChange={datepickerChangeHandler}
              />
            </div>
            <div className={`${styles.buttons}`}>
              <Button
                size="large"
                block
                className={`${styles.bigBtn}`}
                onClick={() => {
                  handleTableChange(undefined, undefined, undefined);
                  handleFilterParams(filterParams);
                  setApplyButtonClicked(true);
                  updateTableData();
                  setCurrentPage(1);
                }}
              >
                {t('apply')}
              </Button>
            </div>
          </div>
        </MainBonusFilter>
      </div>
      <Table
        onChange={(_, __, sort) => {
          if (Array.isArray(sort)) {
            sort = sort[0];
          }
          sortRef.current.sorter = typeof sort.column?.sorter === 'function' ? sort.column?.sorter : () => 0;
          sortRef.current.order = sort.order || '';
        }}
        rowKey="id"
        loading={metricsLoading}
        columns={columns(t)}
        dataSource={tableDataToShow}
        size="small"
        pagination={{
          current: currentPage,
          position: ['bottomCenter'],
          showLessItems: true,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50, 100],
          onChange: (page) => setCurrentPage(page),
        }}
        rowSelection={rowSelection}
        locale={{ emptyText: t('emptyTable') }}
      />
    </div>
  );
};

export default BonusAggregation;
