import Session from 'supertokens-auth-react/recipe/session';
import ThirdParty, { Google } from 'supertokens-auth-react/recipe/thirdparty';
import { ThirdPartyPreBuiltUI } from 'supertokens-auth-react/recipe/thirdparty/prebuiltui';

// import i18n from './i18next';

export const SuperTokensConfig = {
  // languageTranslations: {
  //   translationFunc: i18n.t.bind(i18n),
  // },
  appInfo: {
    appName: 'Backoffice',
    apiDomain: window.location.origin,
    websiteDomain: window.location.origin,
    apiBasePath: '/api/auth',
    websiteBasePath: '/signin',
  },
  // recipeList contains all the modules that you want to
  // use from SuperTokens. See the full list here: https://supertokens.com/docs/guides
  recipeList: [
    ThirdParty.init({
      signInAndUpFeature: {
        providers: [Google.init()],
      },
    }),
    Session.init(),
  ],
};

export const PreBuiltUIList = [ThirdPartyPreBuiltUI];
