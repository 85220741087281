import { gql } from '@apollo/client';

export const USER = gql`
  fragment user on User {
    email
  }
`;

export const STATISTIC = gql`
  fragment statistic on Statistic {
    slotId
    currency
    totalDebit
    totalCredit
    ggr
    clientId
    period
  }
`;
export const STATISTIC_TOTAL = gql`
  fragment statisticTotal on StatisticTotal {
    slotId
    currency
    totalDebit
    totalCredit
    totalRefund
    ggr
    clientId
    count
    totalSpin
    totalBuyBet
    totalBuyCount
    totalCreditEur
    totalDebitEur
    totalRefundEur
    totalBuyBetEur
    ggrEur
  }
`;

export const BONUS_AGGREGATION_CHECK = gql`
  fragment bonusAggregationCheck on BonusAggregationCheck {
    operatorId
    bonusCreditSum
    bonusCreditAggregatedSum
  }
`;

export const USER_BONUS = gql`
  fragment userBonus on UserBonus {
    id
    externalId
    status
    totalWinAmount
    slotId
    isFreeBet
    operatorId
    currency
    expiredAt
    period
  }
`;

export const BONUS = gql`
  fragment bonus on Bonus {
    id
    type
  }
`;

export const OPERATOR = gql`
  fragment operator on Operator {
    id
    name
    enabled
    clients {
      id
      name
    }
  }
`;

export const OPERATOR_ON_SLOT = gql`
  fragment operatorOnSlots on OperatorOnSlots {
    id
    name
    enabled
    licenses
    slots {
      ...slot
    }
  }
`;

export const RTP = gql`
  fragment rtp on Rtp {
    slotId
    value
  }
`;

export const SLOT = gql`
  fragment slot on Slot {
    id
    name
    slug
    licenses
  }
`;

export const BALANCE_SUMMARY = gql`
  fragment balanceSummary on balanceSummary {
    id
    clientId
    slotId
    currency
    totalDebit
    totalCredit
    totalRefund
    ggr
  }
`;
