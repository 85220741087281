import React from 'react';
import { SortOrder } from 'antd/es/table/interface';
import { TFunction } from 'i18next';
import { TOperator, type TBonusAggregation } from '../../types';
import { getFormattedMoney } from '../AccountingReports/Ggr';
import styles from './styles.module.scss';

const moneyMap = (value: number): string => getFormattedMoney(value, false);

const columns = (t: TFunction) => {
  return [
    {
      title: t('balanceOperatorName'),
      dataIndex: 'operatorId',
      key: 'operatorId',
      sorter: (a: TBonusAggregation, b: TBonusAggregation) => a.operatorId.localeCompare(b.operatorId),
      onCell: () => ({ style: { whiteSpace: 'nowrap' } }),
    },
    {
      title: t('balanceClientName'),
      dataIndex: 'clientId',
      key: 'clientId',
      sorter: (a: TBonusAggregation, b: TBonusAggregation) => a.clientId.localeCompare(b.clientId),
      onCell: () => ({ style: { whiteSpace: 'nowrap' } }),
    },
    {
      title: t('bonusCreditSum'),
      dataIndex: 'bonusCreditSum',
      key: 'bonusCreditSum',
      sorter: (a: TBonusAggregation, b: TBonusAggregation) => a.bonusCreditSum - b.bonusCreditSum,
      render: moneyMap,
      align: 'center' as const,
    },
    {
      title: t('bonusCreditAggregatedSum'),
      dataIndex: 'bonusCreditAggregatedSum',
      key: 'bonusCreditAggregatedSum',
      sorter: (a: TBonusAggregation, b: TBonusAggregation) => a.bonusCreditAggregatedSum - b.bonusCreditAggregatedSum,
      render: moneyMap,
      align: 'center' as const,
    },
    {
      title: t('bonusBalanceCheck'),
      dataIndex: 'bonusBalanceCheck',
      key: 'bonusBalanceCheck',
      sorter: (a: TBonusAggregation, b: TBonusAggregation) =>
        (a.bonusBalanceCheck ? 1 : 0) - (b.bonusBalanceCheck ? 1 : 0),
      align: 'center' as const,
      defaultSortOrder: 'descend' as SortOrder,
      render: (text: string, item: TBonusAggregation) =>
        item.bonusCreditSum != item.bonusCreditAggregatedSum ? (
          <div className={`${styles.dot} ${styles.redDot}`} />
        ) : (
          <div className={`${styles.dot} ${styles.greenDot}`} />
        ),
    },
  ];
};

const formatTableData = (data: TBonusAggregation[], operators: TOperator[]): { data: TBonusAggregation[] } => {
  const mappedData: TBonusAggregation[] = data?.map((item, index) => {
    const operator = operators.find(
      (operator: { clients: { id: string; name: string }[] }) =>
        operator.clients.find((client: { id: string; name: string }) => client.id === item.operatorId)?.id ===
        item.operatorId,
    );
    const clientName =
      operator?.clients.find((i: { id: string; name: string }) => i.id === item.operatorId)?.name || '';

    return {
      id: index,
      clientId: clientName,
      operatorId: operator?.name || '',
      bonusCreditAggregatedSum: item.bonusCreditAggregatedSum,
      bonusCreditSum: item.bonusCreditSum,
      bonusBalanceCheck: item.bonusCreditSum != item.bonusCreditAggregatedSum,
    };
  });

  return {
    data: mappedData,
  };
};

const useTable = () => {
  return { columns, formatTableData };
};

export default useTable;
