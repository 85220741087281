import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

const clientLink = () => {
  return new HttpLink({
    uri: '/graphql',
  });
};

const cache = new InMemoryCache();

const errorLink = onError(({ graphQLErrors, networkError }) => {
  console.log(`[Network error]: ${networkError}`);
  console.log(`[graphQLErrors error]: ${graphQLErrors}`);
});

const httpLink = clientLink();

export const client = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache,
});
