import { TFunction } from 'i18next';
import { TBalance, TBalanceCsv, TOperator, TSlot } from '../../types';
import { getFormattedMoney } from './Ggr';

const moneySorter = (a: TBalance, b: TBalance, key: string, normalizeToEur: boolean): number => {
  let aVal = a[(key + (normalizeToEur ? 'Eur' : '')) as keyof TBalance] as number;
  if (typeof aVal !== 'number') {
    aVal = -Infinity;
  }
  let bVal = b[(key + (normalizeToEur ? 'Eur' : '')) as keyof TBalance] as number;
  if (typeof bVal !== 'number') {
    bVal = -Infinity;
  }
  if (aVal === bVal) return 0;
  return aVal > bVal ? 1 : -1;
};

const columns = (t: TFunction, normalizeToEur: boolean) => {
  const keyMap = (key: string): string => (normalizeToEur ? key + 'Eur' : key);
  const moneyMap = (value: number): string => getFormattedMoney(value, normalizeToEur);

  return [
    {
      title: t('balanceOperatorName'),
      dataIndex: 'operatorId',
      key: 'operatorId',
      sorter: (a: TBalance, b: TBalance) => a.operatorId.localeCompare(b.operatorId),
      onCell: () => ({ style: { whiteSpace: 'nowrap' } }),
    },
    {
      title: t('balanceClientName'),
      dataIndex: 'clientId',
      key: 'clientId',
      sorter: (a: TBalance, b: TBalance) => a.clientId.localeCompare(b.clientId),
      onCell: () => ({ style: { whiteSpace: 'nowrap' } }),
    },
    {
      title: t('balanceGameName'),
      dataIndex: 'slotId',
      key: 'slotId',
      sorter: (a: TBalance, b: TBalance) => a.slotId.localeCompare(b.slotId),
    },
    {
      title: t('metricsCurrency'),
      dataIndex: 'currency',
      key: 'currency',
      sorter: (a: TBalance, b: TBalance) => a.currency.localeCompare(b.currency),
    },
    {
      title: t('balanceTotalDebit'),
      dataIndex: keyMap('totalDebit'),
      key: 'totalDebit',
      sorter: (a: TBalance, b: TBalance) => moneySorter(a, b, 'totalDebit', normalizeToEur),
      render: moneyMap,
      align: 'right' as const,
    },
    {
      title: t('balanceTotalCredit'),
      dataIndex: keyMap('totalCredit'),
      key: 'totalCredit',
      sorter: (a: TBalance, b: TBalance) => moneySorter(a, b, 'totalCredit', normalizeToEur),
      render: moneyMap,
      align: 'right' as const,
    },
    {
      title: t('balanceTotalRefund'),
      dataIndex: keyMap('totalRefund'),
      key: 'totalRefund',
      sorter: (a: TBalance, b: TBalance) => moneySorter(a, b, 'totalRefund', normalizeToEur),
      render: moneyMap,
      align: 'right' as const,
    },
    {
      title: t('balanceTotalSpin'),
      dataIndex: 'totalSpin',
      key: 'totalSpin',
      sorter: (a: TBalance, b: TBalance) => a.totalSpin - b.totalSpin,
      align: 'center' as const,
    },
    {
      title: t('balanceTotalBuyBet'),
      dataIndex: keyMap('totalBuyBet'),
      key: 'totalBuyBet',
      sorter: (a: TBalance, b: TBalance) => moneySorter(a, b, 'totalBuyBet', normalizeToEur),
      render: moneyMap,
      align: 'right' as const,
    },
    {
      title: t('balanceTotalBuyBetCount'),
      dataIndex: 'totalBuyCount',
      key: 'totalBuyCount',
      sorter: (a: TBalance, b: TBalance) => a.totalBuyCount - b.totalBuyCount,
      align: 'center' as const,
    },
    {
      title: t('balanceGgr'),
      dataIndex: keyMap('ggr'),
      key: 'ggr',
      sorter: (a: TBalance, b: TBalance) => moneySorter(a, b, 'ggr', normalizeToEur),
      render: moneyMap,
      onCell: (record: TBalance) =>
        record[normalizeToEur ? 'ggrEur' : 'ggr']
          ? { style: { color: (record.ggrEur || record.ggr) >= 0 ? 'green' : 'red' } }
          : {},
      align: 'right' as const,
    },
  ];
};

const exportHeaders = (t: TFunction<'translation', undefined>) => [
  { label: t('balanceClientName'), key: 'clientId' },
  { label: t('balanceOperatorName'), key: 'operatorId' },
  { label: t('balanceGameName'), key: 'slotId' },
  { label: t('metricsCurrency'), key: 'currency' },
  { label: t('balanceTotalDebit'), key: 'totalDebit' },
  { label: t('balanceTotalDebit') + ' (EUR)', key: 'totalDebitEur' },
  { label: t('balanceTotalCredit'), key: 'totalCredit' },
  { label: t('balanceTotalCredit') + ' (EUR)', key: 'totalCreditEur' },
  { label: t('metricsTotalRefund'), key: 'totalRefund' },
  { label: t('balanceTotalRefund') + ' (EUR)', key: 'totalRefundEur' },
  { label: t('balanceTotalSpin'), key: 'totalSpin' },
  { label: t('balanceTotalBuyBet'), key: 'totalBuyBet' },
  { label: t('balanceTotalBuyBet') + ' (EUR)', key: 'totalBuyBetEur' },
  { label: t('balanceTotalBuyBetCount'), key: 'totalBuyCount' },
  { label: t('balanceGgr'), key: 'ggr' },
  { label: t('balanceGgr') + ' (EUR)', key: 'ggrEur' },
];

const formatTableData = (
  data: TBalance[],
  operators: TOperator[],
  slots: TSlot[],
): { data: TBalanceCsv[]; summary: TBalanceCsv } => {
  const summary = {
    id: -1,
    clientId: '',
    clientName: '',
    operatorId: '',
    operatorName: '',
    slotId: '',
    currency: '',
    totalDebit: 0,
    totalCredit: 0,
    totalRefund: 0,
    totalSpin: 0,
    totalBuyBet: 0,
    totalBuyCount: 0,
    ggr: 0,
    totalCreditEur: 0,
    totalDebitEur: 0,
    totalRefundEur: 0,
    totalBuyBetEur: 0,
    ggrEur: 0,
  };

  const mappedData = data?.map((item, index) => {
    const operator = operators.find(
      (operator: { clients: { id: string; name: string }[] }) =>
        operator.clients.find((client: { id: string; name: string }) => client.id === item.clientId)?.id ===
        item.clientId,
    );
    const slot = slots.find((i: { id: string; name: string }) => i.id === item.slotId);
    const clientId = operator?.clients.find((i: { id: string; name: string }) => i.id === item.clientId)?.name || '';
    const operatorId = operator?.name || '';
    const slotId = slot ? slot.name : item.slotId;

    summary.totalSpin += item.totalSpin;
    summary.totalBuyCount += item.totalBuyCount;
    summary.totalCreditEur += item.totalCreditEur;
    summary.totalDebitEur += item.totalDebitEur;
    summary.totalRefundEur += item.totalRefundEur;
    summary.totalBuyBetEur += item.totalBuyBetEur;
    summary.ggrEur += item.ggrEur;

    return {
      id: index,
      clientId,
      operatorId,
      slotId: slotId,
      currency: item.currency,
      totalDebit: item.totalDebit,
      totalCredit: item.totalCredit,
      totalRefund: item.totalRefund,
      totalSpin: item.totalSpin,
      totalBuyBet: item.totalBuyBet,
      totalBuyCount: item.totalBuyCount,
      ggr: item.ggr,
      totalCreditEur: item.totalCreditEur,
      totalDebitEur: item.totalDebitEur,
      totalRefundEur: item.totalRefundEur,
      totalBuyBetEur: item.totalBuyBetEur,
      ggrEur: item.ggrEur,
    };
  });

  summary.id = -1;

  return {
    data: mappedData,
    summary,
  };
};

const useTable = () => {
  return { exportHeaders, columns, formatTableData };
};

export default useTable;
