import React, { memo } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import * as RR from 'react-router-dom';
import { SuperTokensWrapper } from 'supertokens-auth-react';
import { SessionAuth } from 'supertokens-auth-react/recipe/session';
import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react/ui';
import Ggr from '../../components/AccountingReports/Ggr';
import BonusAggregation from '../../components/BonusAggregation/BonusAggregation';
import Client from '../../components/Client/ClientList';
import CurrenciesList from '../../components/Currencies/CurrenciesList';
import Dashboard from '../../components/Dashboard';
import NotFound from '../../components/NotFound';
import Rtp from '../../components/Rtp';
import UserBonus from '../../components/UserBonus/UserBonus';
import Version from '../../components/Version';
import { PreBuiltUIList } from '../../config';

const RenderRoutes = () => (
  <SuperTokensWrapper>
    <BrowserRouter>
      <Routes>
        {getSuperTokensRoutesForReactRouterDom(RR, PreBuiltUIList)}
        <Route
          path="/"
          element={
            <SessionAuth>
              <Dashboard />
            </SessionAuth>
          }
        >
          <Route path="clients">
            <Route index element={<Client />} />
          </Route>
          <Route path="rtp" element={<Rtp />} />
          <Route path="currencies" element={<CurrenciesList />} />
          <Route path="userBonus" element={<UserBonus />} />
          <Route path="/" index element={<Ggr />} />
          <Route path="bonusAggregation" index element={<BonusAggregation />} />
        </Route>
        <Route path="*" element={<NotFound />} />
        <Route path="__version__" element={<Version />} />
      </Routes>
    </BrowserRouter>
  </SuperTokensWrapper>
);

export default memo(RenderRoutes);
