import React from 'react';
import { useTranslation } from 'react-i18next';
import { Radio, Space } from 'antd';
import style from './style.module.scss';

interface TesterSelectorProps {
  value: boolean | undefined;
  setValue: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

const TesterSelector = ({ value, setValue }: TesterSelectorProps) => {
  const { t } = useTranslation();

  return (
    <div className={style.radioFilterItemsWrap}>
      <Radio.Group onChange={(evt) => setValue(evt.target.value)} defaultValue={value}>
        <Space direction="vertical">
          <Radio value={false}>{t('testersRealOnly')}</Radio>
          <Radio value={true}>{t('testersTestersOnly')}</Radio>
          <Radio value={undefined}>{t('testersAll')}</Radio>
        </Space>
      </Radio.Group>
    </div>
  );
};

export default TesterSelector;
