import { ApolloProvider } from '@apollo/client';
import 'antd/dist/reset.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import SuperTokens from 'supertokens-auth-react';
import { ConfigProvider } from 'antd';
import enUS from 'antd/es/locale/en_US';
import Routes from './common/Routes/Routes';
import { SuperTokensConfig } from './config';
import { client } from './graphql';
import i18n from './i18next';
import reportWebVitals from './reportWebVitals';
import './styles/styles.scss';

SuperTokens.init(SuperTokensConfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <ConfigProvider
      locale={enUS}
      theme={{
        token: {
          colorPrimary: '#B3A063',
          colorError: '#fa541c',
          colorText: '#626262',
          borderRadius: 6,
        },
      }}
    >
      <I18nextProvider i18n={i18n}>
        <ApolloProvider client={client}>
          <Routes />
        </ApolloProvider>
      </I18nextProvider>
    </ConfigProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
