import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { t, TFunction } from 'i18next';
import Me from '../../common/ui/UserMe';
import { LanguageSelector } from './LanguageSelector';
import styles from './styles.module.scss';

const { Content, Footer, Header } = Layout;

const MENU = (t: TFunction<'translation', undefined>) => [
  {
    label: <Link to="currencies">{t('menuCurrency')}</Link>,
    key: 'currencies',
    roles: [],
  },
  {
    label: <Link to="clients">{t('menuClients')}</Link>,
    key: 'clients',
    roles: [],
  },
  {
    label: <Link to="rtp">{t('menuRtp')}</Link>,
    key: 'rtp',
    roles: [],
  },
  {
    label: t('accountingMenu'),
    key: 'accounting',
    roles: [],
    children: [
      {
        label: <Link to="/">{t('menuGgr')}</Link>,
        key: 'ggr',
        roles: [],
      },
      {
        label: <Link to="bonusAggregation">{t('menuBam')}</Link>,
        key: 'bma',
        roles: [],
      },
    ],
  },
  {
    label: <Link to="userBonus">{t('menuUserBonus')}</Link>,
    key: 'userBonus',
    roles: [],
  },
];

function Dashboard() {
  return (
    <>
      <Layout className={styles.layout}>
        <Header className={styles.header}>
          <div className={styles.header__container}>
            <img src="/logo.svg" alt="Logo" height="45px" />
            <Menu mode="horizontal" items={MENU(t)} />
            <div className={styles.header__aditional}>
              <LanguageSelector key="lng" />
              <Me key="profile" />
            </div>
          </div>
        </Header>
        <Content style={{ margin: '0 16px' }}>
          <Outlet />
        </Content>
        <Footer style={{ textAlign: 'center' }}>Back Office Phoenix7 ©</Footer>
      </Layout>
    </>
  );
}

export default Dashboard;
