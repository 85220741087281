import React from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { Divider, List } from 'antd';
import { getCurrenciesGql } from '../../graphql';
import styles from './styles.module.scss';

const formatTableData = (data?: string[]): { index: number; value: string }[] => {
  if (!data) {
    return [];
  }
  return data.map((item, index) => ({
    index: index + 1,
    value: item,
  }));
};

const exportHeaders = [
  { label: '#', key: 'index' },
  { label: 'currency', key: 'value' },
];

function CurrenciesList() {
  const { t } = useTranslation();
  const { data } = useQuery<{ currencies: string[] }>(getCurrenciesGql, {
    variables: {
      all: true,
    },
  });

  return (
    <div className="main_container">
      <div className="table-top-space">
        {data?.currencies && (
          <CSVLink
            data={formatTableData(data?.currencies)}
            headers={exportHeaders}
            filename={'currencies.csv'}
            className={styles.exportBtn}
          >
            {t('exportCurrencies')}
          </CSVLink>
        )}
      </div>
      <Divider orientation="left">{t('currencyTitle')}</Divider>
      <List
        grid={{ column: 10 }}
        size="small"
        bordered
        dataSource={data?.currencies}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
    </div>
  );
}

export default CurrenciesList;
