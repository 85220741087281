import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { Button, Checkbox, Dropdown, Table } from 'antd';
import { TFunction } from 'i18next';
import { getOperatorsGql, getRtpGql, getSlotsGql } from '../../graphql';
import i18n from '../../i18next';
import { TRtp, TSlot } from '../../types';

const columns = (t: TFunction<'translation', undefined>, slots?: TSlot[]) => [
  {
    title: t('metricsName'),
    dataIndex: 'slotId',
    key: 'slotId',
    render: (v: string) => {
      if (slots) {
        const slot = slots.find((i) => i.id === v);
        return slot ? slot.name : v;
      }
      return v;
    },
  },
  {
    title: t('rtp'),
    dataIndex: 'value',
    width: '50%',
    render: (v: number) => {
      return new Intl.NumberFormat(i18n.language, { style: 'percent', maximumFractionDigits: 2 }).format(v);
    },
  },
];

function Rtp() {
  const [operator, setOperator] = React.useState('');
  const { data: slotsData } = useQuery<{ slots: TSlot[] }>(getSlotsGql);

  const { data: rtpData, loading } = useQuery<{ rtp: TRtp[] }>(getRtpGql, {
    variables: {
      filter: operator
        ? {
            operator,
          }
        : null,
    },
  });
  const { data: operatorsData } = useQuery<{ operators: { id: string; name: string }[] }>(getOperatorsGql);

  const items = React.useMemo(() => {
    if (operatorsData?.operators) {
      return operatorsData.operators.map((item) => ({
        key: item.id,
        label: (
          <Checkbox
            key={item.id}
            checked={item.id === operator}
            onChange={() => {
              setOperator((prev) => {
                if (prev === item.id) {
                  return '';
                }
                return item.id;
              });
            }}
          >
            {item.name}
          </Checkbox>
        ),
      }));
    }
    return [];
  }, [operator, operatorsData]);

  const { t } = useTranslation();

  return (
    <div className="main_container">
      <h1 className="heder_title">{t('rtpTitle')}</h1>
      <div className="table-top-space">
        <Dropdown menu={{ items }} disabled={!items.length}>
          <Button className="filter-btn">
            <span className="material-symbols-outlined">filter_list</span>FILTER
          </Button>
        </Dropdown>
      </div>
      <Table
        loading={loading}
        columns={columns(t, slotsData?.slots)}
        rowKey="slotId"
        dataSource={rtpData?.rtp}
        pagination={{ position: ['bottomCenter'] }}
      />
    </div>
  );
}

export default Rtp;
