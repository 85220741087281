import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Button, Checkbox, DatePicker, Dropdown, Table } from 'antd';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { getOperatorsGql, getSlotsGql, getUsesBonusesGql } from '../../graphql';
import { TOperator, TSlot, TUserBonus } from '../../types';
import { DATE_FORMAT } from '../AccountingReports/useTimezoneRangepicker';

const { RangePicker } = DatePicker;

type FilterParams = {
  from: string;
  to: string;
  operators: string[];
  isFreeBet: boolean | null;
};

const columns = (t: TFunction<'translation', undefined>, slots?: TSlot[], operators?: TOperator[]) => [
  {
    title: t('externalId'),
    dataIndex: 'externalId',
    key: 'externalId',
  },
  {
    title: t('status'),
    dataIndex: 'status',
    key: 'status',
    sorter: (a: TUserBonus, b: TUserBonus) => a.status.localeCompare(b.currency),
  },
  {
    title: t('totalWinAmount'),
    dataIndex: 'totalWinAmount',
    key: 'totalWinAmount',
    sorter: (a: TUserBonus, b: TUserBonus) => a.totalWinAmount - b.totalWinAmount,
  },
  {
    title: t('slotId'),
    dataIndex: 'slotId',
    key: 'slotId',
    render: (v: string) => {
      if (slots) {
        const slot = slots.find((i) => i.id === v);
        return slot ? slot.name : v;
      }
      return v;
    },
  },
  //   {
  //     title: t('isFreeBet'),
  //     dataIndex: 'isFreeBet',
  //     key: 'isFreeBet',
  //   },
  {
    title: t('operatorId'),
    dataIndex: 'operatorId',
    key: 'operatorId',
    render: (v: string) => {
      if (operators) {
        const operator = operators.find((i) => i.id === v);
        return operator ? operator.name : v;
      }
      return v;
    },
  },
  {
    title: t('currency'),
    dataIndex: 'currency',
    key: 'currency',
    sorter: (a: TUserBonus, b: TUserBonus) => a.currency.localeCompare(b.currency),
  },
  {
    title: t('expiredAt'),
    dataIndex: 'expiredAt',
    key: 'expiredAt',
    render: (v: string | null) => (v ? dayjs(v).format(DATE_FORMAT).toString() : v),
  },
  {
    title: t('period'),
    dataIndex: 'period',
    key: 'period',
    render: (v: string) => dayjs(v).format(DATE_FORMAT).toString(),
  },
];

function UserBonus() {
  const { t } = useTranslation();

  const [filterParams, setFilterParams] = useState<FilterParams>({
    from: dayjs(Date.now()).subtract(1, 'day').format(DATE_FORMAT).toString(),
    to: dayjs(new Date()).format(DATE_FORMAT).toString(),
    operators: [],
    isFreeBet: true,
  });

  const { data: operatorsData } = useQuery<{ operators: TOperator[] }>(getOperatorsGql);
  const { data: slotsData } = useQuery<{ slots: TSlot[] }>(getSlotsGql);
  const [getUserBonuses, { data: userBonusesData }] = useLazyQuery<
    { userBonuses: TUserBonus[] },
    {
      filter: {
        to: string;
        from: string;
        operators: string[];
      };
    }
  >(getUsesBonusesGql);

  useEffect(() => {
    if (filterParams.operators.length) {
      getUserBonuses({
        variables: {
          filter: {
            ...filterParams,
          },
        },
      });
    }
  }, [filterParams]);

  const items = useMemo(() => {
    if (operatorsData?.operators) {
      return operatorsData.operators.map((item) => ({
        key: item.id,
        label: (
          <Checkbox
            key={item.id}
            checked={filterParams.operators.includes(item.id)}
            onChange={() => {
              setFilterParams((prev) => {
                if (prev.operators.includes(item.id)) {
                  const operators = prev.operators.filter((i) => i !== item.id);
                  return {
                    ...prev,
                    operators,
                  };
                }
                return {
                  ...prev,
                  operators: [...prev.operators, item.id],
                };
              });
            }}
          >
            {item.name}
          </Checkbox>
        ),
      }));
    }
    return [];
  }, [filterParams.operators, operatorsData]);
  return (
    <div className="main_container">
      <h1 className="heder_title">{t('userBonusTitle')}</h1>
      <div className="table-top-space">
        <Dropdown menu={{ items }} disabled={!items.length}>
          <Button className="filter-btn">
            <span className="material-symbols-outlined">filter_list</span>FILTER
          </Button>
        </Dropdown>
        <RangePicker
          format={DATE_FORMAT}
          defaultValue={[dayjs(filterParams.from), dayjs(filterParams.to)]}
          onChange={(v, date) => {
            setFilterParams((prev): FilterParams => {
              return {
                ...prev,
                from: date[0],
                to: date[1],
              };
            });
          }}
        />
      </div>
      <Table
        rowKey="id"
        columns={columns(t, slotsData?.slots, operatorsData?.operators)}
        dataSource={userBonusesData?.userBonuses}
        pagination={{ position: ['bottomCenter'] }}
        locale={{ emptyText: 'Select an Operator in the FILTER to load data' }}
      />
    </div>
  );
}

export default UserBonus;
