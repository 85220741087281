import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { TKeyFilterParams } from '../AccountingReports/Ggr';
import MainFilterItem from './MainFilterItem';
import style from './style.module.scss';

interface FilterItem {
  name: string;
  slug: string;
  selected?: boolean;
}

interface FilterGroupProps extends React.PropsWithChildren {
  clients: FilterItem[];
  operators: FilterItem[];
  selectedClients: FilterItem[];
  selectedOperators: FilterItem[];
  onCheckboxChange: (filter: TKeyFilterParams, options: FilterItem[]) => void;
  showCountryFilterItem: boolean;
  onApplyFilters?: () => void;
  applyButtonClicked?: boolean;
}

const MainBonusFilter: React.FC<FilterGroupProps> = ({
  clients,
  operators,
  selectedClients,
  selectedOperators,
  onCheckboxChange,
  applyButtonClicked,
  children,
}) => {
  const [isAnyOperatorChecked, setIsAnyOperatorChecked] = useState(false);
  const [isAnyClientChecked, setIsAnyClientChecked] = useState(false);

  useEffect(() => {
    setIsAnyOperatorChecked(selectedOperators.length > 0);
  }, [selectedOperators]);

  useEffect(() => {
    setIsAnyClientChecked(selectedClients.length > 0);
  }, [selectedClients]);

  return (
    <div className={style['main-filter']}>
      <div className={style['main-filter__col']}>
        <div className={`${style.title} ${style.operators}`}>{t('operators')}</div>
        <MainFilterItem
          isError={!isAnyOperatorChecked && applyButtonClicked}
          title={t('all')}
          options={operators}
          showInvertItem={false}
          selectedOptions={selectedOperators}
          onCheckboxChange={(options) => {
            onCheckboxChange('operators', options);
            onCheckboxChange('clients', []);
          }}
        />
      </div>
      <div className={style['main-filter__col']}>
        <div className={`${style.title} ${style.clients}`}>{t('clients')}</div>
        <MainFilterItem
          isError={!isAnyClientChecked && applyButtonClicked}
          title={t('all')}
          options={clients}
          showInvertItem={false}
          selectedOptions={selectedClients}
          onCheckboxChange={(options) => onCheckboxChange('clients', options)}
        />
      </div>
      {children}
    </div>
  );
};

export default MainBonusFilter;
