import React from 'react';
import { Link } from 'react-router-dom';
import { Result } from 'antd';

function NotFound() {
  return (
    <Result status="404" title="404" subTitle="Sorry, something went wrong." extra={<Link to="/">Back Home</Link>} />
  );
}

export default NotFound;
